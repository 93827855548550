.brandlogo {
  width: 100%;
  height: 12%;
  padding: 1.8% 2% 1%;
  display: flex;
  justify-content: left;
}

.barsearch {
  width: 100%;
  height: 6%;
  padding-left: 3%;
  padding-right: 4%;
  align-items: flex-start;
  justify-content: left;
}

.searchbox {
  justify-items: left;
}

.img_logo {
  width: 25%;
  height: 100%;
}
.maindiv {
  width: 100%;
  height: 160%;
}
.firsthalf {
  display: flex;
  width: 100%;
  height: 38%;

  padding: 0% 2% 0%;
}

.secondhalf {
  display: flex;
  width: 100%;
  height: 38%;
  padding: 0% 2% 0.5%;
}

.sectionplot1 {
  width: 50%;
  background-color: white;
  margin-right: 0.5%;
  overflow-y: scroll;
}

.sectionplot3 {
  width: 50%;
  background-color: white;
  margin-right: 0.5%;
}
.sectionplot {
  width: 50%;
  background-color: white;
  margin-left: 0.5%;
  justify-content: center;
}

.newscss {
  width: 100%;
  height: 4%;
  display: flex;
  padding: 0% 2% 0 0;
  justify-content: flex-start;
  text-align: start;
}

.activeplatform {
  display: flex;

  justify-content: flex-start;
  text-align: start;
  height: 7%;
  padding-top: 3%;
  padding-bottom: 0%;
  padding-left: 2%;
  padding-right: 2%;
}

.spantag1 {
  justify-content: left;
  width: 50%;
  height: 15%;
  padding-left: 4%;
}
.spantag2 {
  padding-left: 4%;
  height: 15%;
  width: 50%;
}

.spantag0 {
  justify-content: left;
  width: 50%;
  padding-left: 5%;
  padding-top: 1%;
}

.icon_btn1 {
  width: 20%;
  font-size: 100%;
  font-weight: bolder;
}

/*Active movers table*/
.firstCellOFEachRow:hover {
  text-decoration: underline;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}
.table_cells2:nth-child(1) {
  font-weight: bold;
  color: #076281;
  font-size: 16px;
  cursor: pointer;
  min-width: 100px;
}
.table_cells2:nth-child(4) {
  font-weight: bold;
  color: #77ff00;
}

/*Decliners Table*/
.firstCellOFEachRow:hover {
  text-decoration: underline;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}
.table_cells3:nth-child(1) {
  font-weight: bold;
  color: #076281;
  font-size: 16px;
  cursor: pointer;
  min-width: 100px;
}
.table_cells3:nth-child(4) {
  font-weight: bold;
  color: #ff4500;
}

.newsLink {
  font-weight: bolder;
  color: #076281;
  cursor: default;
}
