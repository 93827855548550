.modal-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: white;
  width: 600px;
  margin: auto;
  // border: 1px solid #ccc;
  border-radius: 5px;

  .autocomplete-wrap {
    background-color: #002e64;
    input {
      color: #ffffff;
      //   background-color: #002e64;
    }
    button {
      svg {
        color: #ffffff;
      }
    }
    .autocomplete-options {
      color: #ffffff;
    }
  }

  .date-and-switch {
    display: flex;
    padding: 2%;
    gap: 6%;
    .row-div {
      display: flex;
      flex-direction: column;
      .text-field {
        background-color: #ededed;
        color: #6d6d6d;
      }
    }
    .date-component {
      width: 50%;
      background-color: #002e64;
      input {
        color: #ffffff;
      }
      input::placeholder {
        color: #ffffff;
      }
      button {
        svg {
          color: #ffffff;
        }
      }
    }
    .switch-component {
      width: 50%;

      .Long-switch .MuiSwitch-thumb {
        background-color: #ff0000;
      }

      .Short-switch .MuiSwitch-thumb {
        background-color: #05c00d;
      }
    }
  }
  .entry-price-field input {
    color: initial;
  }

  .stop-loss-field {
    background-color: initial;
    color: initial;
  }

  .stop-loss-field input {
    color: initial;
  }
  //   .css-zw3mfo-MuiModal-root-MuiDialog-root {
  //     p {
  //       text-decoration: none;
  //     }
  //   }
  //   .MuiFormControl-root {
  //     label {
  //       color: white;
  //     }
  //     .MuiOutlinedInput-root {
  //       background-color: #002e64;
  //       color: white;
  //       input {
  //         color: #ffffff;
  //       }
  //     }

  //     button {
  //       color: white;
  //     }
  //   }

  //   .MuiFormControl-root .MuiTextField-root .text-field {
  //     .MuiOutlinedInput-root {
  //       //   background-color: gray;
  //     }
  //   }
  //   .css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
  //     color: white;
  //   }
  //   .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  //     color: white;
  //   }
  //   .css-wb57ya-MuiFormControl-root-MuiTextField-root {
  //     background-color: #002e64;
  //   }
}

.modal-content > * {
  margin: 10px 0;
}

.modal-actions {
  gap: 5%;
  display: flex;
  justify-content: space-between;
}

.entry-price-field {
  background-color: initial;
  color: initial;
}
