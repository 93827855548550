.ana_container {
  margin: 0 2%;
}

.ana_Table {
  /* padding-left: 2%;
  padding-right: 2%; */
  padding: 0 0.5%;
}
.heading-col {
  font-weight: bold !important;
  cursor: default;
}

.heading-col:nth-child(1) {
  min-width: 350px;
}
.heading-col:nth-child(2) {
  min-width: 155px;
  text-align: center;
}
.heading-col:nth-child(3) {
  min-width: 195px;
  text-align: center;
}
.heading-col:nth-child(4) {
  text-align: center;
  min-width: 170px;
}
.heading-col:nth-child(5) {
  min-width: 108px;
  text-align: center;
}
.heading-col:nth-child(6) {
  min-width: 108px;
  text-align: center;
}
.heading-col:nth-child(7) {
  min-width: 215px;
  text-align: center;
}
.heading-col:nth-child(10) {
  /* min-width: 335px; */
  text-align: center;
  
}
.cells {
  height: 120px !important;
}
.cells:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}
.catalyst_data {
  display: flex;
  width: 400px;
  gap: 5%;
}
.catalyst_dataLeft {
  width: 80px;
}
.catalyst_dataRight {
  width: 300px;
  display: flex;
  flex-direction: column;
  margin-top: -10px;
}
.catalyst-second {
  margin-left: 1rem !important;
}

.selectBox {
  margin: 0 !important;
  padding: 0 !important;
}

.subtitle {
  margin-right: 800px;
  margin-top: 50px;
  font-weight: 600;
}
.customeButton {
  padding: 13px !important;
}

 .timeline-container{
    width: 200px;
   padding: 2%;
    display: flex;
    justify-content: space-evenly;

   
  }

  .number-icon{
    width: 25px;
    cursor: pointer;
  }