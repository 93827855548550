.searchContainer {
  margin-left: 2%;
}
.Upcoming_table1 {
  /* padding-left: 2%;
  padding-right: 2%;  */
  padding: 0 0.5%;
  height: 90%;
}
.upcoming_results {
  width: 100%;
  height: 5vh;
  margin-left: 0%;
  margin-bottom: -5px;
  padding-right: 2%;
  padding-top: 1%;
  border-radius: 5px;
  text-align: end;
  background-color: white;
}

.heading {
  font-weight: bold;
  text-align: left;
  margin-left: 2%;
  cursor: default;
}
.sub_heading {
  text-align: left;
  cursor: default;
}
.selectBox {
  margin: 0 !important;
  padding: 0 !important;
}
.table_container {
  margin: 1% 2%;
  height: 73%;
}

.subtitle {
  margin-right: 800px;
  margin-top: 50px;
  font-weight: 600;
}
.customeButton {
  padding: 3% !important;
  min-width: 110px !important;
  height: 100%;
  border-radius: 5px 5px 5px 5px !important;
}
.coutom_headCol {
  font-weight: bold !important;
  cursor: default;
  padding: 0.5% !important;
}
.filterbutton {
  width: 100% !important;
  /* width: 60% !important; */
}
.button:hover {
  background-color: #0f26a6 !important;
}
.table {
  max-height: 80vh;
  margin-bottom: 10px !important;
  overflow-y: scroll;
}
.tableHeader {
  position: sticky;
  top: 0;
}
.table_cells {
  cursor: default;
}
.table_cells:nth-child(8) {
  min-width: 80px;
}
.table_cells:nth-child(3) {
  min-width: 90px;
}
.table_cells:nth-child(2) {
  min-width: 350px;
  text-align: justify;
}
.table_cells:nth-child(1) {
  font-weight: bold;
  color: #076281;
  font-size: 15px;
  cursor: pointer;
  min-width: 50px;
}
.table_cells:nth-child(7) {
  min-width: 60px;
}
.table_cells:nth-child(5) {
  min-width: 100px;
}
.table_cells:nth-child(4) {
  min-width: 100px;
}
.table_cells:nth-child(10) {
  min-width: 100px;
}
.firstCellOFEachRow:hover {
  text-decoration: underline;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}
.analysedText:hover {
  text-decoration: underline;
}
.MuiTableContainer-root {
  /* height: 500px; */
  /* padding-bottom: 5px; */
}
/* .upcoming_headCol:nth-child(2) {
  text-align: left;
} */

@media only screen and (max-width: 125%) {
  .Upcoming_table1 {
    max-height: 60vh;
  }
}

@media only screen and (max-width: 100%) {
  .Upcoming_table1 {
    max-height: 70vh;
  }
}

/* height: 61vh; */
