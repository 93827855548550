.cardmedia {
  width: 100%;
  border: 2px black;
  // height: 50%;
}
.newsmain {
  padding-left: 2%;
  padding-top: 1%;
}

.ccd {
  width: 100%;
  border-radius: 1% black;
  border: 2px black;
  align-items: center;
}
.newscontent {
  max-height: 20%;
}
.carddiv {
  width: 100%;

  // height: 10%;
  padding-top: 1%;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 1%;
  border: 2px black;
  display: "inline-block";
  cursor: pointer;
}

.upperdiv {
  width: 100%;
  height: 100%;
}

.paginateDiv {
  width: 100%;
  height: 10%;
}

.titleNews {
  width: 100%;
  height: 18%;
  padding: 1.5% 4% 4%;
  display: block;
}
.backdiv {
  display: flex;
  padding-bottom: 2%;
  justify-content: flex-start;
}

.newsPara {
  padding-left: 3%;
  padding-right: 4%;
  padding-top: 1%;
  padding-bottom: 4%;
  font-size: medium;
  //  font-weight: ;
  // left: 83px;
  // top: 354px;

  overflow-y: auto;
  word-wrap: break-word;
  text-align: justify;
  position: absolute;
  font-family: "Montserrat", "Courier New", Courier, monospace;
  line-height: 20px;
}

.title_news {
  padding-left: 3%;
  padding-right: 4%;
  padding-top: 2%;
  padding-bottom: 2%;
  background-color: aliceblue;
  font-weight: bold;
  font-size: larger;
}

.detailsPaper {
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 3%;
  padding-bottom: 3%;
  align-items: center;
}
