.prior_page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.prior_page > h2 {
  margin-left: 2%;
  margin-top: 2%;
}

.prior_data {
  background-color: white;
  width: 96%;
  margin: 2%;
  padding: 2%;
}
.form_data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Prior_btn {
  background-color: #002e64 !important;
  color: white !important;
  margin-top: 60px !important;
  padding-right: 2% !important;
  margin-left: 1% !important;
  align-self: center !important;
}
.saved_data {
  width: 100% !important;
  background-color: white;
}
.internal_data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.internal_data > img,
h3 {
  margin-top: 40px;
}
.description {
  font-size: 16px;
}
