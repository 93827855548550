.logout_btn {
  margin-top: 25vh;
}
.side_menu {
  height: 50vh;
}
.brandlogoSide {
  margin: 10% 0;
  cursor: pointer;
}

.sidebar-options {
  background-color: #ffffff;
}

.sidebar-options:hover {
  color: #1890ff;
}

.upcoming-catalyst-calendar {
  .sidebar-options {
    background-color: #ffffff;
    color: #000000;
  }

  .sidebar-options:hover {
    color: #1890ff;
  }
  .sidebar-options-active {
    background-color: #e6f7ff;
    a {
      color: #1890ff !important;
    }
  }
}
.upcoming-catalyst-calendar-active {
  color: #1890ff;
  .sidebar-options {
    background-color: #ffffff;
    color: #000000;
  }

  .sidebar-options:hover {
    color: #1890ff;
  }
  .sidebar-options-active {
    background-color: #e6f7ff;
    a {
      color: #1890ff !important;
    }
  }
}

.sidebar-options-active {
  background-color: #e6f7ff;
  color: #1890ff;
}
