.exit-modal {
  display: flex;
  gap: 6%;
  .exit-btn {
    background-color: #002e64;
    color: #ffffff;
  }
  .date-component {
    width: 20%;
    background-color: #002e64;
    input {
      color: #ffffff;
    }
    input::placeholder {
      color: #ffffff;
    }
    button {
      svg {
        color: #ffffff;
      }
    }
  }
}
