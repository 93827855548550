.ag-header-cell{
  background-color: #1b6d85 !important;
  color: #fff !important;
}
.ag-header-cell-filtered{
    background-color: #1b6d85 !important;
    color: #fff !important;
}
.companyHeader{
    padding-left:75px !important;
}
.companyHeader1 {
    padding-left: 30px !important;
}
.companyCell{
    text-align: left;
}
.tickerCell:hover{
   text-decoration: underline;
   font-size:16px !important;
}




::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 8px;
}
.ag-theme-alpine {
    width: 100% !important;
   
    overflow-y: auto;
    overflow-x: auto;
  }
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
/* .ag-header-cell span {
    color: #fff !important;
} */

/*Customeheader Styling*/
.customHeaderMenuButton {
    float: left;
    margin: 0 0 0 3px;
}

.customHeaderLabel {
    float: left;
    margin: 0 0 0 3px;
}

.customSortDownLabel {
    float: left;
    margin: 0 0 0 3px;
}
.customFilterLabel{
    float: left;
    margin: 0 0 0 3px;
}

.customSortUpLabel {
    float: left;
    margin: 0;
}

.customSortRemoveLabel {
    float: left;
    margin: 0 0 0 3px;
    font-size: 11px;
}

.active {
    color: cornflowerblue;
}



.ag-body-viewport-wrapper.ag-layout-normal {
    overflow-x: auto !important;
    overflow-y: auto !important; 
}


.ag-root {
    width: fit-content !important; 
}

.ag-header-cell span {
    color: #fff !important; 
}


.active {
    color: cornflowerblue !important; 
}

.pos-table {
    border-collapse: collapse;
    width: 29%;
    box-shadow: "5px 5px #D3D3D3";
  }
  
  .pos-table-header,
  .pos-table-cell {
    border: 1px solid black;
    border-radius: 5px;
    text-align: center;
    padding: 8px;
    font-size: 14px;
  }
  
  .pos-table-header {
    background-color: #076281;
    color: white;
  }