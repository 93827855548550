@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,100&display=swap);
body{margin:0;font-family:lato, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;box-sizing:border-box;overflow:auto}body .my-table .my-class{background-color:blue;width:10px}body .my-table .test-div{border:1px solid}body .button{background-color:#002e64;color:white}code{font-family:source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace}

.logout_btn{margin-top:25vh}.side_menu{height:50vh}.brandlogoSide{margin:10% 0;cursor:pointer}.sidebar-options{background-color:#ffffff}.sidebar-options:hover{color:#1890ff}.upcoming-catalyst-calendar .sidebar-options{background-color:#ffffff;color:#000000}.upcoming-catalyst-calendar .sidebar-options:hover{color:#1890ff}.upcoming-catalyst-calendar .sidebar-options-active{background-color:#e6f7ff}.upcoming-catalyst-calendar .sidebar-options-active a{color:#1890ff !important}.upcoming-catalyst-calendar-active{color:#1890ff}.upcoming-catalyst-calendar-active .sidebar-options{background-color:#ffffff;color:#000000}.upcoming-catalyst-calendar-active .sidebar-options:hover{color:#1890ff}.upcoming-catalyst-calendar-active .sidebar-options-active{background-color:#e6f7ff}.upcoming-catalyst-calendar-active .sidebar-options-active a{color:#1890ff !important}.sidebar-options-active{background-color:#e6f7ff;color:#1890ff}

body {
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin: 0;
}
::-webkit-scrollbar {
  width: 0px;
}
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: auto;
  color: #000133;
}

.container {
  display: flex;
  flex: 1 1;
}

.drawer {
  background: white;
  width: 258px;
  width: 300px;
}

.main {
  flex: 1 1;
  background: #f7f5f5;
  overflow: auto;
  height: 99vh;
  background-image: url(/static/media/mainbodyimg.3f101115.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.main > div {
  overflow-x: scroll;
  overflow-y: scroll;
 
}

.ana_container {
  margin: 0 2%;
}

.ana_Table {
  /* padding-left: 2%;
  padding-right: 2%; */
  padding: 0 0.5%;
}
.heading-col {
  font-weight: bold !important;
  cursor: default;
}

.heading-col:nth-child(1) {
  min-width: 350px;
}
.heading-col:nth-child(2) {
  min-width: 155px;
  text-align: center;
}
.heading-col:nth-child(3) {
  min-width: 195px;
  text-align: center;
}
.heading-col:nth-child(4) {
  text-align: center;
  min-width: 170px;
}
.heading-col:nth-child(5) {
  min-width: 108px;
  text-align: center;
}
.heading-col:nth-child(6) {
  min-width: 108px;
  text-align: center;
}
.heading-col:nth-child(7) {
  min-width: 215px;
  text-align: center;
}
.heading-col:nth-child(10) {
  /* min-width: 335px; */
  text-align: center;
  
}
.cells {
  height: 120px !important;
}
.cells:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}
.catalyst_data {
  display: flex;
  width: 400px;
  grid-gap: 5%;
  gap: 5%;
}
.catalyst_dataLeft {
  width: 80px;
}
.catalyst_dataRight {
  width: 300px;
  display: flex;
  flex-direction: column;
  margin-top: -10px;
}
.catalyst-second {
  margin-left: 1rem !important;
}

.selectBox {
  margin: 0 !important;
  padding: 0 !important;
}

.subtitle {
  margin-right: 800px;
  margin-top: 50px;
  font-weight: 600;
}
.customeButton {
  padding: 13px !important;
}

 .timeline-container{
    width: 200px;
   padding: 2%;
    display: flex;
    justify-content: space-evenly;

   
  }

  .number-icon{
    width: 25px;
    cursor: pointer;
  }
.searchContainer {
  margin-left: 2%;
}
.Upcoming_table1 {
  /* padding-left: 2%;
  padding-right: 2%;  */
  padding: 0 0.5%;
  height: 90%;
}
.upcoming_results {
  width: 100%;
  height: 5vh;
  margin-left: 0%;
  margin-bottom: -5px;
  padding-right: 2%;
  padding-top: 1%;
  border-radius: 5px;
  text-align: end;
  background-color: white;
}

.heading {
  font-weight: bold;
  text-align: left;
  margin-left: 2%;
  cursor: default;
}
.sub_heading {
  text-align: left;
  cursor: default;
}
.selectBox {
  margin: 0 !important;
  padding: 0 !important;
}
.table_container {
  margin: 1% 2%;
  height: 73%;
}

.subtitle {
  margin-right: 800px;
  margin-top: 50px;
  font-weight: 600;
}
.customeButton {
  padding: 3% !important;
  min-width: 110px !important;
  height: 100%;
  border-radius: 5px 5px 5px 5px !important;
}
.coutom_headCol {
  font-weight: bold !important;
  cursor: default;
  padding: 0.5% !important;
}
.filterbutton {
  width: 100% !important;
  /* width: 60% !important; */
}
.button:hover {
  background-color: #0f26a6 !important;
}
.table {
  max-height: 80vh;
  margin-bottom: 10px !important;
  overflow-y: scroll;
}
.tableHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.table_cells {
  cursor: default;
}
.table_cells:nth-child(8) {
  min-width: 80px;
}
.table_cells:nth-child(3) {
  min-width: 90px;
}
.table_cells:nth-child(2) {
  min-width: 350px;
  text-align: justify;
}
.table_cells:nth-child(1) {
  font-weight: bold;
  color: #076281;
  font-size: 15px;
  cursor: pointer;
  min-width: 50px;
}
.table_cells:nth-child(7) {
  min-width: 60px;
}
.table_cells:nth-child(5) {
  min-width: 100px;
}
.table_cells:nth-child(4) {
  min-width: 100px;
}
.table_cells:nth-child(10) {
  min-width: 100px;
}
.firstCellOFEachRow:hover {
  text-decoration: underline;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}
.analysedText:hover {
  text-decoration: underline;
}
.MuiTableContainer-root {
  /* height: 500px; */
  /* padding-bottom: 5px; */
}
/* .upcoming_headCol:nth-child(2) {
  text-align: left;
} */

@media only screen and (max-width: 125%) {
  .Upcoming_table1 {
    max-height: 60vh;
  }
}

@media only screen and (max-width: 100%) {
  .Upcoming_table1 {
    max-height: 70vh;
  }
}

/* height: 61vh; */

.historical_container {
  margin: 2%;
}
.searchContainer {
  margin-left: 3%;
}

.heading {
  font-weight: bold;
  margin-right: 1000px;
  margin-top: 50px;
}

.selectBox {
  margin: 0 !important;
  padding: 0 !important;
}

.subtitle {
  margin-right: 800px;
  margin-top: 50px;
  font-weight: 600;
}
.customeButton {
  padding: 13px !important;
}

.marginTop {
  margin-top: 0 !important ;
}
.historical_table {
  padding-left: 2%;
  padding-right: 2%;
}
.historical_results {
  width: 100%;
  height: 5vh;
  margin-left: 0%;
  margin-bottom: -5px;
  padding-right: 2%;
  padding-top: 1%;
  border-radius: 5px;
  text-align: end;
  background-color: white;
}
.head_col {
  font-weight: bold !important;
  cursor: default;
}
.head_col:nth-child(6) {
  min-width: 110px;
}

/*Grid Css Start*/

.buttonNmodal {
  display: flex;
  justify-content: space-evenly;
}
.buttonNmodal1 {
  display: flex;
  justify-content: space-between;
  justify-content: center;
  width: 33%;
  height: 0.9in;
  padding: 10px;

  /* height: 10px; */
}
/* .ant-btn ant-btn-primary{
  max-height: 29px;
} */
/* .ant-btn.ant-btn-primary{
  height: 10px;
} */

.buttonNmodal2 {
  /* margin-right: 10px; */
  /* padding-right: 2px; */
  display: flex;
  text-align: left;
  justify-content: flex-end;
  width: 67%;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  /* min-height: 20px;
   */
  width: 4in;
  height: 0.5in;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-text {
  /* min-height: 20px;
   */
  height: 0.5in;
  width: 2in;
}
.ag-theme-alpine {
  width: 100%;
  /* height: "42vh"; */
  /* padding: 0% 2%; */
  height: auto;
  overflow-y: hidden;
}
.ag-header-cell {
  background-color: #1b6d85 !important;
  color: #fff !important;
}

.ag-header-cell-filtered {
  background-color: #1b6d85 !important;
  color: #fff !important;
}

.companyHeader {
  padding-left: 5px !important;
}
.cmpheader {
  padding-left: 15px !important;
}
.modal:hover {
  text-decoration: underline;
}
.companyCell {
  text-align: left;
  word-break: normal;
}

.tickerCell:hover {
  text-decoration: underline;
  font-size: 10px !important;
}

/* .ag-header-cell span {
    color: #fff !important;
} */

/*Customeheader Styling*/
.customHeaderMenuButton {
  float: left;
  margin: 0 0 0 3px;
}

.customHeaderLabel {
  float: left;
  margin: 0 0 0 3px;
}

.customSortDownLabel {
  float: left;
  margin: 0 0 0 3px;
}

.customFilterLabel {
  float: left;
  margin: 0 0 0 3px;
}

.customSortUpLabel {
  float: left;
  margin: 0;
}

.customSortRemoveLabel {
  float: left;
  margin: 0 0 0 3px;
  font-size: 11px;
}

.active {
  color: cornflowerblue;
}
.textWrapper {
  word-wrap: break-word;
  white-space: nowrap;
}

/*button css*/
.customButton {
  padding: 3% !important;
  min-width: 110px !important;
  min-height: 55px !important;
  height: 100%;
  border-radius: 5px 5px 5px 5px !important;
}
.filterbutton {
  width: 100% !important;
}
.button:hover {
  background-color: #0f26a6 !important;
}

/* for historical table css */

.searchContainer {
  margin-left: 2%;
}

.upcoming_results {
  width: 100%;
  height: 5vh;
  margin-left: 0%;
  margin-bottom: -5px;
  padding-right: 2%;
  padding-top: 1%;
  border-radius: 5px;
  text-align: end;
  background-color: white;
}

.heading {
  font-weight: bold;
  text-align: left;
  margin-left: 2%;
  cursor: default;
}
.sub_heading {
  text-align: left;
  cursor: default;
}
.selectBox {
  margin: 0 !important;
  padding: 0 !important;
}
.table_container1 {
  margin: 1% 2%;
  /* height: 20px; */
  /* height: 455px; */
}

/* .Upcoming_table1{

  height: 60vh;
  margin: 1% 2%;
} */

.subtitle {
  margin-right: 800px;
  margin-top: 50px;
  font-weight: 600;
}
.customeButton {
  padding: 3% !important;
  min-width: 110px !important;
  height: 100%;
  border-radius: 5px 5px 5px 5px !important;
}
.coutom_headCol {
  /* font-weight: bold !important; */
  cursor: default;
  padding: 0.5% !important;
  width: 10% !important;
}
.filterbutton {
  width: 100% !important;
  /* width: 60% !important; */
}
.button:hover {
  background-color: #0f26a6 !important;
}
.table {
  max-height: 50vh;
  margin-bottom: 10px !important;
  overflow-y: scroll;
}
.tableHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.table_cells1 {
  cursor: default;
}
.table_cells1:nth-child(8) {
  min-width: 200px;
}
.table_cells1:nth-child(3) {
  min-width: 110px;
}
.table_cells1:nth-child(2) {
  min-width: 170px;
  text-align: left;
}
.table_cells1:nth-child(1) {
  font-weight: bold;
  color: #076281;
  font-size: 16px;
  cursor: pointer;
  min-width: 100px;
}
.table_cells1:nth-child(7) {
  min-width: 100px;
}
.table_cells1:nth-child(5) {
  min-width: 100px;
}
.table_cells1:nth-child(4) {
  min-width: 100px;
}
.table_cells1:nth-child(9) {
  min-width: 150px;
}
.table_cells1:nth-child(10) {
  min-width: 500px;
}
.firstCellOFEachRow:hover {
  text-decoration: underline;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}
.analysedText:hover {
  text-decoration: underline;
}

/* antd modal propetries */

.ant-modal {
  border-radius: 20px;
  /* Only border-radius here */
}

.ant-modal-content {
  border-radius: 20px;
  /* border: 10px solid black; */
}

/* modal  */
.modalSide {
  width: 25%;
}

/* Advance search */
.ant-form-item {
  width: 500px;
}
.ant-form-item-control {
}
.Alabel {
  width: 200px;
}
.checkboxOp {
  width: 75%;
}

.btn_model {
  background-color: #076281;
  color: #fff;
  font-weight: bold;
}

.ant-modal-title {
  text-align: center;
}

.ant-calendar-picker-input.ant-input {
  border-radius: 0px;
  width: 100px;
}

.prior_page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.prior_page > h2 {
  margin-left: 2%;
  margin-top: 2%;
}

.prior_data {
  background-color: white;
  width: 96%;
  margin: 2%;
  padding: 2%;
}
.form_data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Prior_btn {
  background-color: #002e64 !important;
  color: white !important;
  margin-top: 60px !important;
  padding-right: 2% !important;
  margin-left: 1% !important;
  align-self: center !important;
}
.saved_data {
  width: 100% !important;
  background-color: white;
}
.internal_data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.internal_data > img,
h3 {
  margin-top: 40px;
}
.description {
  font-size: 16px;
}

.prior_page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.prior_page > h2 {
  margin-left: 2%;
  margin-top: 2%;
}

.prior_data {
  background-color: white;
  width: 96%;
  margin: 2%;
  padding: 2%;
}
.form_data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Prior_btn {
  background-color: #002e64 !important;
  color: white !important;
  margin-top: 60px !important;
  padding-right: 2% !important;
  margin-left: 1% !important;
  align-self: center !important;
}
.saved_data {
  width: 100% !important;
  background-color: white;
}
.internal_data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.internal_data > img,
h3 {
  margin-top: 40px;
}
.description {
  font-size: 16px;
}

.searchfield {
  width: 100%;
}
.newscardsmain {
  width: 100%;
}

.cardmedia{width:100%;border:2px black}.newsmain{padding-left:2%;padding-top:1%}.ccd{width:100%;border-radius:1% black;border:2px black;align-items:center}.newscontent{max-height:20%}.carddiv{width:100%;padding-top:1%;padding-left:2%;padding-right:2%;padding-bottom:1%;border:2px black;display:"inline-block";cursor:pointer}.upperdiv{width:100%;height:100%}.paginateDiv{width:100%;height:10%}.titleNews{width:100%;height:18%;padding:1.5% 4% 4%;display:block}.backdiv{display:flex;padding-bottom:2%;justify-content:flex-start}.newsPara{padding-left:3%;padding-right:4%;padding-top:1%;padding-bottom:4%;font-size:medium;overflow-y:auto;word-wrap:break-word;text-align:justify;position:absolute;font-family:"Montserrat", "Courier New", Courier, monospace;line-height:20px}.title_news{padding-left:3%;padding-right:4%;padding-top:2%;padding-bottom:2%;background-color:aliceblue;font-weight:bold;font-size:larger}.detailsPaper{padding-left:2%;padding-right:2%;padding-top:3%;padding-bottom:3%;align-items:center}

.brandlogo {
  width: 100%;
  height: 12%;
  padding: 1.8% 2% 1%;
  display: flex;
  justify-content: left;
}

.barsearch {
  width: 100%;
  height: 6%;
  padding-left: 3%;
  padding-right: 4%;
  align-items: flex-start;
  justify-content: left;
}

.searchbox {
  justify-items: left;
}

.img_logo {
  width: 25%;
  height: 100%;
}
.maindiv {
  width: 100%;
  height: 160%;
}
.firsthalf {
  display: flex;
  width: 100%;
  height: 38%;

  padding: 0% 2% 0%;
}

.secondhalf {
  display: flex;
  width: 100%;
  height: 38%;
  padding: 0% 2% 0.5%;
}

.sectionplot1 {
  width: 50%;
  background-color: white;
  margin-right: 0.5%;
  overflow-y: scroll;
}

.sectionplot3 {
  width: 50%;
  background-color: white;
  margin-right: 0.5%;
}
.sectionplot {
  width: 50%;
  background-color: white;
  margin-left: 0.5%;
  justify-content: center;
}

.newscss {
  width: 100%;
  height: 4%;
  display: flex;
  padding: 0% 2% 0 0;
  justify-content: flex-start;
  text-align: start;
}

.activeplatform {
  display: flex;

  justify-content: flex-start;
  text-align: start;
  height: 7%;
  padding-top: 3%;
  padding-bottom: 0%;
  padding-left: 2%;
  padding-right: 2%;
}

.spantag1 {
  justify-content: left;
  width: 50%;
  height: 15%;
  padding-left: 4%;
}
.spantag2 {
  padding-left: 4%;
  height: 15%;
  width: 50%;
}

.spantag0 {
  justify-content: left;
  width: 50%;
  padding-left: 5%;
  padding-top: 1%;
}

.icon_btn1 {
  width: 20%;
  font-size: 100%;
  font-weight: bolder;
}

/*Active movers table*/
.firstCellOFEachRow:hover {
  text-decoration: underline;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}
.table_cells2:nth-child(1) {
  font-weight: bold;
  color: #076281;
  font-size: 16px;
  cursor: pointer;
  min-width: 100px;
}
.table_cells2:nth-child(4) {
  font-weight: bold;
  color: #77ff00;
}

/*Decliners Table*/
.firstCellOFEachRow:hover {
  text-decoration: underline;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}
.table_cells3:nth-child(1) {
  font-weight: bold;
  color: #076281;
  font-size: 16px;
  cursor: pointer;
  min-width: 100px;
}
.table_cells3:nth-child(4) {
  font-weight: bold;
  color: #ff4500;
}

.newsLink {
  font-weight: bolder;
  color: #076281;
  cursor: default;
}

.RewardRisk_pipelineTableHeader__20KBP{
    background-color: #FFFF !important;
}
.RewardRisk_InfoTag__g79Op{
   min-width: 150px !important;
}
.RewardRisk_fontbold__2zLb5{
    font-weight: bold !important;
}
.Upcoming_table {
  padding: 2%;
}
.heading {
  font-weight: bold;
}

.selectBox {
  margin: 0 !important;
  padding: 0 !important;
}

/* .subtitle {
  margin-right: 800px;
  margin-top: 50px;
  font-weight: 600;
} */
.customeButton {
  padding:3% !important;
  min-width: 110px !important;
  max-height: 55px !important;
  min-height: 55px !important;
  border-radius: 5px 5px 5px 5px !important;
}
.button:hover {
  background-color: #0f26a6 !important;
}
.filterbutton {
  width: 100% !important;
}

/* .topTable {
  margin-left: 10%;
  margin-right: 10%;
  height: auto;
  margin-bottom: 10px !important;
}
.bottomTable {
  margin-left: 10%;
  margin-right: 10%;
  height: auto;
  margin-top: 10px !important;
}
.check_table {
  padding-left: 2%;
  padding-right: 2%;
} */

.checklist_headCells:nth-child(1) {
  text-align: left;
  min-width: 300px !important;
  max-width: 300px !important;
  width:300px !important;
}

.checklist_headCells:nth-child(2) {
  text-align: left;
   min-width: 400px !important;
  max-width: 400px !important;
  width:400px !important;
}

.checklist_headCells:nth-child(4) {
  min-width: 120px;
  text-align: left;
}
.checklist_headCells:nth-child(3) {
  text-align: left;
  min-width: 300px !important;
  max-width: 300px !important;
  width: 300px !important;
}
.checklist_results {
  text-align: left;
  background-color: white;
  height: 5vh;
  margin-bottom: -5px;
  padding-left: 2%;
  padding-top: 1%;
  border-radius: 5px;
}


.pos_page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pos_page > h1,
p {
  margin: 2% 0 1% 2%;
}

.pos_page > p {
  font-size: 16px;
}

.pos_data {
  width: 96%;
  margin: 2%;
  background-color: white;
}

.app-container {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  padding: 1rem;
}

table {
  border-collapse: collapse;
  /* width: 100%; */
  box-shadow: "5px 5px #D3D3D3";
}

th,
td {
  border: 1px solid #ffffff;
  border-radius: 5px;
  text-align: center;
  padding: 8px;
  font-size: 14px;
}

th {
  background-color: #076281;
  color: white;
}

/* td {
  background-color: rgb(205, 235, 253);
} */

form {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}

form td:last-child {
  display: flex;
  justify-content: space-evenly;
}

form * {
  font-size: 14px;
}

.zoom {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  height: 400px;
  margin: 0 auto;
}

.zoom:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.landing_container {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
}

.menu_items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 14vh;                   /*   header height 14 % of total height of screen */
  margin-left: 0;
  padding: 2%;
}
.menu_btn {
  font-size: 16px !important;
  font-weight: bold !important;
  background-color: #076281 !important;
  color: white !important;
  padding: 3% !important;
  min-width: 200px !important;
  height: 50px !important;
  border-radius: 5px 5px 5px 5px !important;
}
.section_1 {
  height: 82vh !important;
  background-color: #f6f6f6;
    /* display: flex;
    justify-content: space-around;
    align-items: center; */
}
.res_pic{
  width: 90%;
  
   object-fit:contain;
}
.dashboard_btn {
  height: 50px !important;
  width: 150px;
  padding-right: 2%;
}
.section_left {
  padding-left: 4%;
  margin-top: 30%;
}
.section_left > h2{
   text-align: left;
  font-size: 32px;
    color: #002e64;
    font-weight: bold;
}
.section_left > h3{
   text-align: left;
  font-size: 28px;
    color: #002e64;
}
.section_right {
  padding-left: 2%;
}

.section_left > span {
  font-size: 32px;
  margin-top: -15px;
}
.section_left > button {
  background-color: #076281;
  color: white;
  font-weight: bold;
  margin-top: 4vh;
}
.section_2 {
  margin-top: 10vh;
  height: 90vh;
  background-color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.link_card {
    max-width: 98%;
    height: auto;
    border-radius: 10px;
    border-width: 0 0 0 18px;    
    border-style: solid;
    border-color: #01abd3;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 2%;
    margin: 10% 0;
}
.link_card>h3 {
  text-align: left;
  font-weight: bold;
  margin: 0 0 2% 0
}

.link_card>p {
  text-align: left;
  margin: 0
}
.section_3 {
  height: 45vh;
  background-color: #f6f6f6;
}
.sec3_pic {
  height: 100%;
  width: 100%;
}
.section_3 > h1 {
  text-align: center;
  padding-top: 5vh;
  padding-bottom: 10vh;
  font-size: 30px;
  font-weight: bold;
  margin-right: 10px;
}
.section_down {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 2%;
  padding-right: 2%;
}
.section_4 {
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section_4 > h1 {
  text-align: center;
  font-weight: bold;
  font-size: 28px;
  padding-top: 5vh;
}
.section_3 {
  height: 60vh;
  background-color: #f6f6f6;
}
.section3_down > h3 {
  padding:5%;
}
/* @media only screen and (max-width: 1072px) {
  .section_1 {
    height: 55vh;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .dashboard_btn {
    height: 50px !important;
    width: 150px;
    padding-right: 2%;
  }
  .section_left {
    display: flex;
    align-items: center;
    text-align: center;
    padding-left: 2%;
  }
  .section_right {
    padding-left: 2%;
  }
  .res_pic {
    height: 336px;
    width: 339px;
  }
  .section_left > h2 {
    margin-top: 3vh;
    font-size: 20px;
    color: #002e64;
    font-weight: bold;
  }
  .section_left > span {
    font-size: 20px;
    margin-top: -15px;
  }
  .section_left > button {
    background-color: #076281;
    color: white;
    font-weight: bold;
    margin-top: 2vh;
  }
  .section_2 {
    margin-top:10vh;
    height: 90vh;
    background-color: white;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .sec3_pic {
    height: 336px;
    width: 600px;
  }
  .link_card {
    max-width: 98%;
    height:auto;
    border-radius: 10px;
    border-width: 0 0 0 18px;
    border-style: solid;
    border-color: #01abd3;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding:2%;
    margin:4% 0;
   
  }
  .link_card > strong {
    font-size: 20px;
  }
  .link_card > span {
    font-size: 18px;
    margin-top: -15px;
  }
  .link_card > h3 {
    text-align: left;
    font-weight: bold;
    margin:0
  }
  .link_card > p {
    text-align: left;
    margin: 0
  }
  .section2_heading {
    margin-top: 37px;
    margin-bottom: 37px;
    text-align: center !important;
  }
  .section_3 {
    height: 75vh;
    background-color: #f6f6f6;
  }
  .section_3 > h1 {
    text-align: center;
    padding-top: 5vh;
    padding-bottom: 5vh;
    font-size: 30px;
    font-weight: bold;
  }
  .section_down {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .section_down > div {
    width: 30%;
  }
  .section3_down > h3 {
    margin-bottom: 50px;
  }
} */
.loginInputSection{
  width:90%;
  margin:auto;
  height:40px;
  border:1px solid #ADADAD;
  border-radius:20px;
  outline:none;
  padding:4%;
}
.loginPageLoginButton{
  width:90%;
  margin:auto;
  height:40px;
  border-radius:20px !important;
  padding:2%;
  background-color:#076281 !important;
  color:#fff !important;
  font-weight:bold !important
}
.sideImageLogo{
  width:100%;
  height:100%;
}

.login-container{margin-top:100px;background:white;width:600px;padding:30px 20px;display:flex;justify-content:center;border:1px solid #e5e8eb;border-radius:4px;box-shadow:0 0 10px #f0f0f0;flex-direction:column}.login-container .login-form{display:flex;flex-direction:column;justify-content:space-between}.login-container .login-form .form-item{margin:10px 0;display:flex;justify-content:center;align-items:baseline}.login-container .login-form .form-item label{color:#353840;margin:0 10px;width:15%;text-align:left}.login-container .login-form .form-item input{width:50%}.login-container .login-form .form-item button{width:100px;font-size:14px;padding:10px 0}

.headingContainer {
  padding: 2.5% 0 2.5% 2.5%;
}

.ag-header-cell{
  background-color: #1b6d85 !important;
  color: #fff !important;
}
.ag-header-cell-filtered{
    background-color: #1b6d85 !important;
    color: #fff !important;
}
.companyHeader{
    padding-left:75px !important;
}
.companyHeader1 {
    padding-left: 30px !important;
}
.companyCell{
    text-align: left;
}
.tickerCell:hover{
   text-decoration: underline;
   font-size:16px !important;
}
.ag-body-viewport-wrapper.ag-layout-normal {
    overflow-x: scroll;
    overflow-y: scroll;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
/* .ag-header-cell span {
    color: #fff !important;
} */

/*Customeheader Styling*/
.customHeaderMenuButton {
    float: left;
    margin: 0 0 0 3px;
}

.customHeaderLabel {
    float: left;
    margin: 0 0 0 3px;
}

.customSortDownLabel {
    float: left;
    margin: 0 0 0 3px;
}
.customFilterLabel{
    float: left;
    margin: 0 0 0 3px;
}

.customSortUpLabel {
    float: left;
    margin: 0;
}

.customSortRemoveLabel {
    float: left;
    margin: 0 0 0 3px;
    font-size: 11px;
}

.active {
    color: cornflowerblue;
}
.firstCellOFEachRow:hover {
    text-decoration: underline;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    cursor: pointer;
}
.select-placeholder-text{font-size:medium}.select{border-color:darkgray}.x_btn{background-color:white;border-color:#ccc;color:black}.outerDiv{display:flex;justify-content:space-between;align-items:center;padding:2%}.innerdiv{width:10%;margin:0px}.heading{font-weight:bolder;padding-left:2%;text-align:left;margin-top:0px}.selectdiv{display:flex;width:70%;justify-content:flex-end}.selectdiv2{display:flex}.para{font-size:16px}.dropdiv{background-color:#fff;min-width:15vw}.textarea{padding-left:2%}.bubblePlot{background-color:#fff;margin:0% 2%}@media only screen and (max-width: 1150px){.selectdiv{display:flex;flex-direction:column;grid-gap:10px;gap:10px}.outerDiv{display:flex;flex-direction:column;grid-gap:10px;gap:10px}.css-1f7uvch-control{width:37vw}.dropdiv{width:37vw}}

.portfolio .linechart-heading{font-size:22px;font-weight:700;color:#6d6d6d;display:flex;padding:1% 2% 1% 2%;-webkit-clip-path:polygon(0 1%, 100% 0%, 82% 100%, 0% 100%);clip-path:polygon(0 1%, 100% 0%, 82% 100%, 0% 100%);background-color:#d9d9d9;width:45%}.portfolio .second-row{display:flex;padding:0% 2% 2% 2%;grid-gap:4%;gap:4%}.portfolio .second-row .paper-boxes{width:22%}.portfolio .second-row .paper-boxes .text-paper-box{color:#002e64;font-size:18px;font-weight:500}.portfolio .second-row .paper-boxes .text-paper-box-row2{color:#002e64;font-size:30px;font-weight:600}.portfolio .second-row .paper-boxes-realised{width:18%;cursor:pointer}.portfolio .second-row .paper-boxes-realised .text-paper-box{color:#002e64;font-size:18px;font-weight:500}.portfolio .second-row .paper-boxes-realised .text-paper-box-row2{color:#002e64;font-size:30px;font-weight:600}.portfolio .table-heading{background-color:#d9d9d9;color:#6d6d6d;font-size:22px;font-weight:700;padding:0.5%}.portfolio .position-table .MuiTableContainer-root{height:350px;padding-bottom:5px}.dropdown-grid{padding:2% 2% 0% 2%;grid-gap:4.8%}.dropdown-grid .css-1v7axyl-MuiPaper-root{width:13vw}.dropdown-grid .css-1v7axyl-MuiPaper-root .css-1mmtv2s{width:auto}.dropdown-grid .css-1ak039y-MuiPaper-root{width:13vw}.dropdown-grid .css-1ak039y-MuiPaper-root .css-1mmtv2s{width:auto}.dropdown-grid .formControl .MuiOutlinedInput-root{color:white}.dropdown-grid .formControl .MuiOutlinedInput-root svg{color:white}.dropdown-grid .add-button{background-color:#ffffff;color:#6d6d6d;border-radius:5px;font-family:Montserrat;font-size:18px;font-weight:500;text-align:left}.dropdown-grid .MuiButtonBase-root.MuiButton-root.MuiButton-contained{height:auto;width:20.5%}.btn-row{display:flex;grid-gap:2%;gap:2%}.form-add-portfolio{margin-bottom:4%;width:100%}.labels{font-family:Montserrat, Arial, Helvetica, sans-serif;font-size:18px;font-weight:600;line-height:22px;letter-spacing:0em;text-align:left;color:#6d6d6d}

.modal-content{display:flex;flex-direction:column;padding:20px;background-color:white;width:600px;margin:auto;border-radius:5px}.modal-content .autocomplete-wrap{background-color:#002e64}.modal-content .autocomplete-wrap input{color:#ffffff}.modal-content .autocomplete-wrap button svg{color:#ffffff}.modal-content .autocomplete-wrap .autocomplete-options{color:#ffffff}.modal-content .date-and-switch{display:flex;padding:2%;grid-gap:6%;gap:6%}.modal-content .date-and-switch .row-div{display:flex;flex-direction:column}.modal-content .date-and-switch .row-div .text-field{background-color:#ededed;color:#6d6d6d}.modal-content .date-and-switch .date-component{width:50%;background-color:#002e64}.modal-content .date-and-switch .date-component input{color:#ffffff}.modal-content .date-and-switch .date-component input::-webkit-input-placeholder{color:#ffffff}.modal-content .date-and-switch .date-component input::placeholder{color:#ffffff}.modal-content .date-and-switch .date-component button svg{color:#ffffff}.modal-content .date-and-switch .switch-component{width:50%}.modal-content .date-and-switch .switch-component .Long-switch .MuiSwitch-thumb{background-color:#ff0000}.modal-content .date-and-switch .switch-component .Short-switch .MuiSwitch-thumb{background-color:#05c00d}.modal-content .entry-price-field input{color:initial}.modal-content .stop-loss-field{background-color:initial;color:initial}.modal-content .stop-loss-field input{color:initial}.modal-content>*{margin:10px 0}.modal-actions{grid-gap:5%;gap:5%;display:flex;justify-content:space-between}.entry-price-field{background-color:initial;color:initial}

.exit-modal{display:flex;grid-gap:6%;gap:6%}.exit-modal .exit-btn{background-color:#002e64;color:#ffffff}.exit-modal .date-component{width:20%;background-color:#002e64}.exit-modal .date-component input{color:#ffffff}.exit-modal .date-component input::-webkit-input-placeholder{color:#ffffff}.exit-modal .date-component input::placeholder{color:#ffffff}.exit-modal .date-component button svg{color:#ffffff}

.ag-header-cell{
  background-color: #1b6d85 !important;
  color: #fff !important;
}
.ag-header-cell-filtered{
    background-color: #1b6d85 !important;
    color: #fff !important;
}
.companyHeader{
    padding-left:75px !important;
}
.companyHeader1 {
    padding-left: 30px !important;
}
.companyCell{
    text-align: left;
}
.tickerCell:hover{
   text-decoration: underline;
   font-size:16px !important;
}




::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 8px;
}
.ag-theme-alpine {
    width: 100% !important;
   
    overflow-y: auto;
    overflow-x: auto;
  }
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
/* .ag-header-cell span {
    color: #fff !important;
} */

/*Customeheader Styling*/
.customHeaderMenuButton {
    float: left;
    margin: 0 0 0 3px;
}

.customHeaderLabel {
    float: left;
    margin: 0 0 0 3px;
}

.customSortDownLabel {
    float: left;
    margin: 0 0 0 3px;
}
.customFilterLabel{
    float: left;
    margin: 0 0 0 3px;
}

.customSortUpLabel {
    float: left;
    margin: 0;
}

.customSortRemoveLabel {
    float: left;
    margin: 0 0 0 3px;
    font-size: 11px;
}

.active {
    color: cornflowerblue;
}



.ag-body-viewport-wrapper.ag-layout-normal {
    overflow-x: auto !important;
    overflow-y: auto !important; 
}


.ag-root {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important; 
}

.ag-header-cell span {
    color: #fff !important; 
}


.active {
    color: cornflowerblue !important; 
}

.pos-table {
    border-collapse: collapse;
    width: 29%;
    box-shadow: "5px 5px #D3D3D3";
  }
  
  .pos-table-header,
  .pos-table-cell {
    border: 1px solid black;
    border-radius: 5px;
    text-align: center;
    padding: 8px;
    font-size: 14px;
  }
  
  .pos-table-header {
    background-color: #076281;
    color: white;
  }
