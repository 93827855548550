.select-placeholder-text {
 
    font-size: medium;
    }

.select {
    border-color: darkgray;
}

.x_btn {
    background-color: white;
   border-color : hsl(0, 0%, 80%);
    
    color: black;
}

.outerDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2%;
    // width: 155%;
}
.innerdiv{width: 10%;
margin: 0px;}

.heading{
    font-weight: bolder;
    padding-left: 2%;
   text-align: left;
   margin-top: 0px;
//    display: flex;
//    padding: 0px; 
// width: 0%;
    
}

.selectdiv{
    display: flex;
    width: 70%;
    justify-content: flex-end;

}

.selectdiv2{
    display: flex;
}

.para{
    font-size: 16px;
}

.dropdiv{
    background-color: #fff;
    min-width: 15vw;
}

// .paperEle{
//     width: 100%;
//      min-width: 30%;
   
// }

.textarea{
    padding-left: 2%;
   
}

.bubblePlot{
    background-color: #fff;
    margin: 0% 2%;
}

@media only screen and (max-width: 1150px) {
    .selectdiv {
     display: flex;
     flex-direction: column;
     gap: 10px;
    }
    .outerDiv{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .css-1f7uvch-control{
        width: 37vw;
    }
    .dropdiv{
        width: 37vw;

    }
  }