.historical_container {
  margin: 2%;
}
.searchContainer {
  margin-left: 3%;
}

.heading {
  font-weight: bold;
  margin-right: 1000px;
  margin-top: 50px;
}

.selectBox {
  margin: 0 !important;
  padding: 0 !important;
}

.subtitle {
  margin-right: 800px;
  margin-top: 50px;
  font-weight: 600;
}
.customeButton {
  padding: 13px !important;
}

.marginTop {
  margin-top: 0 !important ;
}
.historical_table {
  padding-left: 2%;
  padding-right: 2%;
}
.historical_results {
  width: 100%;
  height: 5vh;
  margin-left: 0%;
  margin-bottom: -5px;
  padding-right: 2%;
  padding-top: 1%;
  border-radius: 5px;
  text-align: end;
  background-color: white;
}
.head_col {
  font-weight: bold !important;
  cursor: default;
}
.head_col:nth-child(6) {
  min-width: 110px;
}

/*Grid Css Start*/

.buttonNmodal {
  display: flex;
  justify-content: space-evenly;
}
.buttonNmodal1 {
  display: flex;
  justify-content: space-between;
  justify-content: center;
  width: 33%;
  height: 0.9in;
  padding: 10px;

  /* height: 10px; */
}
/* .ant-btn ant-btn-primary{
  max-height: 29px;
} */
/* .ant-btn.ant-btn-primary{
  height: 10px;
} */

.buttonNmodal2 {
  /* margin-right: 10px; */
  /* padding-right: 2px; */
  display: flex;
  text-align: left;
  justify-content: flex-end;
  width: 67%;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  /* min-height: 20px;
   */
  width: 4in;
  height: 0.5in;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-text {
  /* min-height: 20px;
   */
  height: 0.5in;
  width: 2in;
}
.ag-theme-alpine {
  width: 100%;
  /* height: "42vh"; */
  /* padding: 0% 2%; */
  height: auto;
  overflow-y: hidden;
}
.ag-header-cell {
  background-color: #1b6d85 !important;
  color: #fff !important;
}

.ag-header-cell-filtered {
  background-color: #1b6d85 !important;
  color: #fff !important;
}

.companyHeader {
  padding-left: 5px !important;
}
.cmpheader {
  padding-left: 15px !important;
}
.modal:hover {
  text-decoration: underline;
}
.companyCell {
  text-align: left;
  word-break: normal;
}

.tickerCell:hover {
  text-decoration: underline;
  font-size: 10px !important;
}

/* .ag-header-cell span {
    color: #fff !important;
} */

/*Customeheader Styling*/
.customHeaderMenuButton {
  float: left;
  margin: 0 0 0 3px;
}

.customHeaderLabel {
  float: left;
  margin: 0 0 0 3px;
}

.customSortDownLabel {
  float: left;
  margin: 0 0 0 3px;
}

.customFilterLabel {
  float: left;
  margin: 0 0 0 3px;
}

.customSortUpLabel {
  float: left;
  margin: 0;
}

.customSortRemoveLabel {
  float: left;
  margin: 0 0 0 3px;
  font-size: 11px;
}

.active {
  color: cornflowerblue;
}
.textWrapper {
  word-wrap: break-word;
  white-space: nowrap;
}

/*button css*/
.customButton {
  padding: 3% !important;
  min-width: 110px !important;
  min-height: 55px !important;
  height: 100%;
  border-radius: 5px 5px 5px 5px !important;
}
.filterbutton {
  width: 100% !important;
}
.button:hover {
  background-color: #0f26a6 !important;
}

/* for historical table css */

.searchContainer {
  margin-left: 2%;
}

.upcoming_results {
  width: 100%;
  height: 5vh;
  margin-left: 0%;
  margin-bottom: -5px;
  padding-right: 2%;
  padding-top: 1%;
  border-radius: 5px;
  text-align: end;
  background-color: white;
}

.heading {
  font-weight: bold;
  text-align: left;
  margin-left: 2%;
  cursor: default;
}
.sub_heading {
  text-align: left;
  cursor: default;
}
.selectBox {
  margin: 0 !important;
  padding: 0 !important;
}
.table_container1 {
  margin: 1% 2%;
  /* height: 20px; */
  /* height: 455px; */
}

/* .Upcoming_table1{

  height: 60vh;
  margin: 1% 2%;
} */

.subtitle {
  margin-right: 800px;
  margin-top: 50px;
  font-weight: 600;
}
.customeButton {
  padding: 3% !important;
  min-width: 110px !important;
  height: 100%;
  border-radius: 5px 5px 5px 5px !important;
}
.coutom_headCol {
  /* font-weight: bold !important; */
  cursor: default;
  padding: 0.5% !important;
  width: 10% !important;
}
.filterbutton {
  width: 100% !important;
  /* width: 60% !important; */
}
.button:hover {
  background-color: #0f26a6 !important;
}
.table {
  max-height: 50vh;
  margin-bottom: 10px !important;
  overflow-y: scroll;
}
.tableHeader {
  position: sticky;
  top: 0;
}
.table_cells1 {
  cursor: default;
}
.table_cells1:nth-child(8) {
  min-width: 200px;
}
.table_cells1:nth-child(3) {
  min-width: 110px;
}
.table_cells1:nth-child(2) {
  min-width: 170px;
  text-align: left;
}
.table_cells1:nth-child(1) {
  font-weight: bold;
  color: #076281;
  font-size: 16px;
  cursor: pointer;
  min-width: 100px;
}
.table_cells1:nth-child(7) {
  min-width: 100px;
}
.table_cells1:nth-child(5) {
  min-width: 100px;
}
.table_cells1:nth-child(4) {
  min-width: 100px;
}
.table_cells1:nth-child(9) {
  min-width: 150px;
}
.table_cells1:nth-child(10) {
  min-width: 500px;
}
.firstCellOFEachRow:hover {
  text-decoration: underline;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}
.analysedText:hover {
  text-decoration: underline;
}

/* antd modal propetries */

.ant-modal {
  border-radius: 20px;
  /* Only border-radius here */
}

.ant-modal-content {
  border-radius: 20px;
  /* border: 10px solid black; */
}

/* modal  */
.modalSide {
  width: 25%;
}

/* Advance search */
.ant-form-item {
  width: 500px;
}
.ant-form-item-control {
}
.Alabel {
  width: 200px;
}
.checkboxOp {
  width: 75%;
}

.btn_model {
  background-color: #076281;
  color: #fff;
  font-weight: bold;
}

.ant-modal-title {
  text-align: center;
}

.ant-calendar-picker-input.ant-input {
  border-radius: 0px;
  width: 100px;
}
