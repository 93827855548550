.ag-header-cell{
  background-color: #1b6d85 !important;
  color: #fff !important;
}
.ag-header-cell-filtered{
    background-color: #1b6d85 !important;
    color: #fff !important;
}
.companyHeader{
    padding-left:75px !important;
}
.companyHeader1 {
    padding-left: 30px !important;
}
.companyCell{
    text-align: left;
}
.tickerCell:hover{
   text-decoration: underline;
   font-size:16px !important;
}
.ag-body-viewport-wrapper.ag-layout-normal {
    overflow-x: scroll;
    overflow-y: scroll;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
/* .ag-header-cell span {
    color: #fff !important;
} */

/*Customeheader Styling*/
.customHeaderMenuButton {
    float: left;
    margin: 0 0 0 3px;
}

.customHeaderLabel {
    float: left;
    margin: 0 0 0 3px;
}

.customSortDownLabel {
    float: left;
    margin: 0 0 0 3px;
}
.customFilterLabel{
    float: left;
    margin: 0 0 0 3px;
}

.customSortUpLabel {
    float: left;
    margin: 0;
}

.customSortRemoveLabel {
    float: left;
    margin: 0 0 0 3px;
    font-size: 11px;
}

.active {
    color: cornflowerblue;
}