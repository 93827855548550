.portfolio {
  .linechart-heading {
    font-size: 22px;
    font-weight: 700;
    color: #6d6d6d;
    display: flex;
    padding: 1% 2% 1% 2%;
    clip-path: polygon(0 1%, 100% 0%, 82% 100%, 0% 100%);
    background-color: #d9d9d9;
    width: 45%;
  }
  .second-row {
    display: flex;
    padding: 0% 2% 2% 2%;
    gap: 4%;
    .paper-boxes {
      // width: 18%;
      width: 22%; //for 125%
      .text-paper-box {
        color: #002e64;
        font-size: 18px;
        font-weight: 500;
      }
      .text-paper-box-row2 {
        color: #002e64;
        font-size: 30px;
        font-weight: 600;
      }
    }
    .paper-boxes-realised {
      width: 18%;

      cursor: pointer;
      .text-paper-box {
        color: #002e64;
        font-size: 18px;
        font-weight: 500;
      }
      .text-paper-box-row2 {
        color: #002e64;
        font-size: 30px;
        font-weight: 600;
      }
    }
  }

  .table-heading {
    background-color: #d9d9d9;
    color: #6d6d6d;
    font-size: 22px;
    font-weight: 700;
    padding: 0.5%;
  }
  .position-table {
    // max-height: 460px;
    // overflow: auto;
    .MuiTableContainer-root {
      height: 350px;
      padding-bottom: 5px;
    }
  }
}
.dropdown-grid {
  padding: 2% 2% 0% 2%;
  // gap: 6.5%;
  grid-gap: 4.8%;

  .css-1v7axyl-MuiPaper-root {
    width: 13vw;
    .css-1mmtv2s {
      width: auto;
    }
  }
  .css-1ak039y-MuiPaper-root {
    width: 13vw;
    .css-1mmtv2s {
      width: auto;
    }
  }
  .formControl {
    .MuiOutlinedInput-root {
      color: white;
      svg {
        color: white;
      }
    }
  }
  .add-button {
    background-color: #ffffff;
    color: #6d6d6d;
    border-radius: 5px;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    // line-height: 22px;
    // letter-spacing: 0em;
    text-align: left;
  }
  .MuiButtonBase-root.MuiButton-root.MuiButton-contained {
    height: auto;
    // width: 16.5%;
    width: 20.5% // for 125%
;
  }
}

.btn-row {
  display: flex;
  gap: 2%;
}
.form-add-portfolio {
  margin-bottom: 4%;
  width: 100%;
}
.labels {
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

  color: #6d6d6d;
}
