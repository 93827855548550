@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,100&display=swap");

body {
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin: 0;
}
::-webkit-scrollbar {
  width: 0px;
}
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: auto;
  color: #000133;
}

.container {
  display: flex;
  flex: 1;
}

.drawer {
  background: white;
  width: 258px;
  width: 300px;
}

.main {
  flex: 1;
  background: #f7f5f5;
  overflow: auto;
  height: 99vh;
  background-image: url("./component/assests/mainbodyimg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.main > div {
  overflow-x: scroll;
  overflow-y: scroll;
 
}
