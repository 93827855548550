.Upcoming_table {
  padding: 2%;
}
.heading {
  font-weight: bold;
}

.selectBox {
  margin: 0 !important;
  padding: 0 !important;
}

/* .subtitle {
  margin-right: 800px;
  margin-top: 50px;
  font-weight: 600;
} */
.customeButton {
  padding:3% !important;
  min-width: 110px !important;
  max-height: 55px !important;
  min-height: 55px !important;
  border-radius: 5px 5px 5px 5px !important;
}
.button:hover {
  background-color: #0f26a6 !important;
}
.filterbutton {
  width: 100% !important;
}

/* .topTable {
  margin-left: 10%;
  margin-right: 10%;
  height: auto;
  margin-bottom: 10px !important;
}
.bottomTable {
  margin-left: 10%;
  margin-right: 10%;
  height: auto;
  margin-top: 10px !important;
}
.check_table {
  padding-left: 2%;
  padding-right: 2%;
} */

.checklist_headCells:nth-child(1) {
  text-align: left;
  min-width: 300px !important;
  max-width: 300px !important;
  width:300px !important;
}

.checklist_headCells:nth-child(2) {
  text-align: left;
   min-width: 400px !important;
  max-width: 400px !important;
  width:400px !important;
}

.checklist_headCells:nth-child(4) {
  min-width: 120px;
  text-align: left;
}
.checklist_headCells:nth-child(3) {
  text-align: left;
  min-width: 300px !important;
  max-width: 300px !important;
  width: 300px !important;
}
.checklist_results {
  text-align: left;
  background-color: white;
  height: 5vh;
  margin-bottom: -5px;
  padding-left: 2%;
  padding-top: 1%;
  border-radius: 5px;
}

