@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,100&display=swap");
body {
  margin: 0;
  font-family: lato, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;   // word font smoothing (no subpixel )  for chrome browser
  -moz-osx-font-smoothing: grayscale;    // word font smoothing (no subpixel )  for mozila browser
  box-sizing: border-box;                // padding + border of box included in width and height
  overflow: auto;                        // allows to clip or add scrollbar if required to see content 
  .my-table {
    .my-class {
      background-color: blue;
      width: 10px;
    }
    .test-div {
      border: 1px solid;
    }
  }
  .button {
    background-color: #002e64;
    color: white;
  }
  // .readoutHeads {
  //   margin-right: 150px;
  //   font-weight: bold;
  // }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
