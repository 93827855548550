.pos_page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pos_page > h1,
p {
  margin: 2% 0 1% 2%;
}

.pos_page > p {
  font-size: 16px;
}

.pos_data {
  width: 96%;
  margin: 2%;
  background-color: white;
}

.app-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem;
}

table {
  border-collapse: collapse;
  /* width: 100%; */
  box-shadow: "5px 5px #D3D3D3";
}

th,
td {
  border: 1px solid #ffffff;
  border-radius: 5px;
  text-align: center;
  padding: 8px;
  font-size: 14px;
}

th {
  background-color: #076281;
  color: white;
}

/* td {
  background-color: rgb(205, 235, 253);
} */

form {
  display: flex;
  gap: 5px;
}

form td:last-child {
  display: flex;
  justify-content: space-evenly;
}

form * {
  font-size: 14px;
}

.zoom {
  transition: transform 0.2s;
  height: 400px;
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(1.3);
}
