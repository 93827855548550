.landing_container {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
}

.menu_items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 14vh;                   /*   header height 14 % of total height of screen */
  margin-left: 0;
  padding: 2%;
}
.menu_btn {
  font-size: 16px !important;
  font-weight: bold !important;
  background-color: #076281 !important;
  color: white !important;
  padding: 3% !important;
  min-width: 200px !important;
  height: 50px !important;
  border-radius: 5px 5px 5px 5px !important;
}
.section_1 {
  height: 82vh !important;
  background-color: #f6f6f6;
    /* display: flex;
    justify-content: space-around;
    align-items: center; */
}
.res_pic{
  width: 90%;
  
   object-fit:contain;
}
.dashboard_btn {
  height: 50px !important;
  width: 150px;
  padding-right: 2%;
}
.section_left {
  padding-left: 4%;
  margin-top: 30%;
}
.section_left > h2{
   text-align: left;
  font-size: 32px;
    color: #002e64;
    font-weight: bold;
}
.section_left > h3{
   text-align: left;
  font-size: 28px;
    color: #002e64;
}
.section_right {
  padding-left: 2%;
}

.section_left > span {
  font-size: 32px;
  margin-top: -15px;
}
.section_left > button {
  background-color: #076281;
  color: white;
  font-weight: bold;
  margin-top: 4vh;
}
.section_2 {
  margin-top: 10vh;
  height: 90vh;
  background-color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.link_card {
    max-width: 98%;
    height: auto;
    border-radius: 10px;
    border-width: 0 0 0 18px;    
    border-style: solid;
    border-color: #01abd3;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 2%;
    margin: 10% 0;
}
.link_card>h3 {
  text-align: left;
  font-weight: bold;
  margin: 0 0 2% 0
}

.link_card>p {
  text-align: left;
  margin: 0
}
.section_3 {
  height: 45vh;
  background-color: #f6f6f6;
}
.sec3_pic {
  height: 100%;
  width: 100%;
}
.section_3 > h1 {
  text-align: center;
  padding-top: 5vh;
  padding-bottom: 10vh;
  font-size: 30px;
  font-weight: bold;
  margin-right: 10px;
}
.section_down {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 2%;
  padding-right: 2%;
}
.section_4 {
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section_4 > h1 {
  text-align: center;
  font-weight: bold;
  font-size: 28px;
  padding-top: 5vh;
}
.section_3 {
  height: 60vh;
  background-color: #f6f6f6;
}
.section3_down > h3 {
  padding:5%;
}
/* @media only screen and (max-width: 1072px) {
  .section_1 {
    height: 55vh;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .dashboard_btn {
    height: 50px !important;
    width: 150px;
    padding-right: 2%;
  }
  .section_left {
    display: flex;
    align-items: center;
    text-align: center;
    padding-left: 2%;
  }
  .section_right {
    padding-left: 2%;
  }
  .res_pic {
    height: 336px;
    width: 339px;
  }
  .section_left > h2 {
    margin-top: 3vh;
    font-size: 20px;
    color: #002e64;
    font-weight: bold;
  }
  .section_left > span {
    font-size: 20px;
    margin-top: -15px;
  }
  .section_left > button {
    background-color: #076281;
    color: white;
    font-weight: bold;
    margin-top: 2vh;
  }
  .section_2 {
    margin-top:10vh;
    height: 90vh;
    background-color: white;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .sec3_pic {
    height: 336px;
    width: 600px;
  }
  .link_card {
    max-width: 98%;
    height:auto;
    border-radius: 10px;
    border-width: 0 0 0 18px;
    border-style: solid;
    border-color: #01abd3;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding:2%;
    margin:4% 0;
   
  }
  .link_card > strong {
    font-size: 20px;
  }
  .link_card > span {
    font-size: 18px;
    margin-top: -15px;
  }
  .link_card > h3 {
    text-align: left;
    font-weight: bold;
    margin:0
  }
  .link_card > p {
    text-align: left;
    margin: 0
  }
  .section2_heading {
    margin-top: 37px;
    margin-bottom: 37px;
    text-align: center !important;
  }
  .section_3 {
    height: 75vh;
    background-color: #f6f6f6;
  }
  .section_3 > h1 {
    text-align: center;
    padding-top: 5vh;
    padding-bottom: 5vh;
    font-size: 30px;
    font-weight: bold;
  }
  .section_down {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .section_down > div {
    width: 30%;
  }
  .section3_down > h3 {
    margin-bottom: 50px;
  }
} */
.loginInputSection{
  width:90%;
  margin:auto;
  height:40px;
  border:1px solid #ADADAD;
  border-radius:20px;
  outline:none;
  padding:4%;
}
.loginPageLoginButton{
  width:90%;
  margin:auto;
  height:40px;
  border-radius:20px !important;
  padding:2%;
  background-color:#076281 !important;
  color:#fff !important;
  font-weight:bold !important
}
.sideImageLogo{
  width:100%;
  height:100%;
}
