.login-container {
  margin-top: 100px;
  background: white;
  width: 600px;
  padding: 30px 20px;
  display: flex;
  justify-content: center;
  border: 1px solid #e5e8eb;
  border-radius: 4px;
  box-shadow: 0 0 10px #f0f0f0;
  flex-direction: column;

  .login-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .form-item {
      margin: 10px 0;
      display: flex;
      justify-content: center;
      align-items: baseline;

      label {
        color: #353840;
        margin: 0 10px;
        width: 15%;
        text-align: left;
      }

      input {
        width: 50%;
      }

      button {
        width: 100px;
        font-size: 14px;
        padding: 10px 0;
      }
    }
  }
}
